<template>
  <div class="wrap">
    <div class="content">
      <div
        class="teeth"
        @click="teethClick(index, item, 'one')"
        v-for="(item, index) in teethOne"
        :key="index"
      >
        <span>{{
          item.number
        }}</span>
        <el-popover
          placement="bottom"
          @after-leave="popoverHide(index, 'one', item)"
          :disabled="readOnly || selectTeethFn(item.number, selectOne)"
          ref="onePopover"
          trigger="click"
        >
          <div class="flex-y-c posr">
            <el-input
              :value="item.width"
              :placeholder="$t('cases.createDiagnosis.jxl')"
              @change="
                disposeNumber({
                  key: item,
                  value: arguments[0],
                  list: selectOne,
                })
              "
              @input="
                disposeNumber({
                  key: item,
                  value: arguments[0],
                  list: selectOne,
                })
              "
              style="width: 1.8rem"
              v-model="item.width"
            ></el-input>
            <span class="input_mm">mm</span>
          </div>
          <div
            v-if="index !== teethOne.length - 1"
            @click="resetNumber(item, 'teethOne', index)"
            :class="{
              active_img: item.width,
              [portImg]: item.width,
              'hvr-bd curp': !selectTeethFn(item.number, selectOne),
            }"
            slot="reference"
          ></div>
        </el-popover>
      </div>
    </div>
    <div class="content">
      <div
        class="teeth"
        @click="teethClick(index, item, 'tow')"
        v-for="(item, index) in teethTow"
        :key="index"
      >
        <span>{{
          item.number
        }}</span>
        <el-popover
          placement="bottom"
          :disabled="readOnly || selectTeethFn(item.number, selectTow)"
          @after-leave="popoverHide(index, 'tow', item)"
          ref="towPopover"
          trigger="click"
        >
          <div class="flex-y-c posr">
            <el-input
              :value="item.width"
              :placeholder="$t('cases.createDiagnosis.jxl')"
              @change="
                disposeNumber({
                  key: item,
                  value: arguments[0],
                  list: selectTow,
                })
              "
              @input="
                disposeNumber({
                  key: item,
                  value: arguments[0],
                  list: selectTow,
                })
              "
              style="width: 1.8rem"
              v-model="item.width"
            ></el-input>
            <span class="input_mm">mm</span>
          </div>
          <div
            v-if="index !== teethTow.length - 1"
            :class="{
              active_img: item.width,
              [portImg]: item.width,
              'hvr-bd curp': !selectTeethFn(item.number, selectTow),
            }"
            @click="resetNumber(item, 'teethTow', index)"
            slot="reference"
          ></div>
        </el-popover>
      </div>
    </div>
    <div
      class="detail"
      v-if="find(totalTeeth, (item) => item.width)"
    >
      <template v-for="(item, index) in totalTeeth">
        <p class="fz16rem mb1rem" :key="index" v-if="item.width">
          {{ item.number }},
          {{ item.nextNumber }}
          {{ $t("cases.createDiagnosis.yljgw") }}
          <span>{{ item.width }} mm</span>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import {
  extend,
  map,
  pick,
  filter,
  each,
  pickBy,
  uniq,
  isNumber,
  find,
  isArray,
} from "lodash";
import { toNumber } from "../../../common/js/Fn";

export default {
  data() {
    return {
      teethOne: [
        {
          number: 18,
          nextNumber: 17,
          width: undefined,
        },
        {
          number: 17,
          nextNumber: 16,
          width: undefined,
        },
        {
          number: 16,
          nextNumber: 15,
          width: undefined,
        },
        {
          number: 15,
          nextNumber: 14,
          width: undefined,
        },
        {
          number: 14,
          nextNumber: 13,
          width: undefined,
        },
        {
          number: 13,
          nextNumber: 12,
          width: undefined,
        },
        {
          number: 12,
          nextNumber: 11,
          width: undefined,
        },
        {
          number: 11,
          nextNumber: 21,
          width: undefined,
        },
        {
          number: 21,
          nextNumber: 22,
          width: undefined,
        },
        {
          number: 22,
          nextNumber: 23,
          width: undefined,
        },
        {
          number: 23,
          nextNumber: 24,
          width: undefined,
        },
        {
          number: 24,
          nextNumber: 25,
          width: undefined,
        },
        {
          number: 25,
          nextNumber: 26,
          width: undefined,
        },
        {
          number: 26,
          nextNumber: 27,
          width: undefined,
        },
        {
          number: 27,
          nextNumber: 28,
          width: undefined,
        },
        {
          number: 28,
          nextNumber: 29,
          width: undefined,
        },
      ],
      teethTow: [
        {
          number: 48,
          nextNumber: 47,
          width: undefined,
        },
        {
          number: 47,
          nextNumber: 46,
          width: undefined,
        },
        {
          number: 46,
          nextNumber: 45,
          width: undefined,
        },
        {
          number: 45,
          nextNumber: 44,
          width: undefined,
        },
        {
          number: 44,
          nextNumber: 43,
          width: undefined,
        },
        {
          number: 43,
          nextNumber: 42,
          width: undefined,
        },
        {
          number: 42,
          nextNumber: 41,
          width: undefined,
        },
        {
          number: 41,
          nextNumber: 31,
          width: undefined,
        },
        {
          number: 31,
          nextNumber: 32,
          width: undefined,
        },
        {
          number: 32,
          nextNumber: 33,
          width: undefined,
        },
        {
          number: 33,
          nextNumber: 34,
          width: undefined,
        },
        {
          number: 34,
          nextNumber: 35,
          width: undefined,
        },
        {
          number: 35,
          nextNumber: 36,
          width: undefined,
        },
        {
          number: 36,
          nextNumber: 37,
          width: undefined,
        },
        {
          number: 37,
          nextNumber: 38,
          width: undefined,
        },
        {
          number: 38,
          nextNumber: 39,
          width: undefined,
        },
      ],
      teethList: [],
      setList: false,
      teethKey: ["teethOne", "teethTow"],
    };
  },
  props: {
    crowdTeethList: {
      type: Array,
    },
    readOnly: {
      type: Boolean,
    },
    productType: {
      type: String,
    },
    commonTeeth: {
      type: Array,
    },
  },
  computed: {
    totalTeeth() {
      return [...this.teethOne, ...this.teethTow];
    },
    selectList() {
      return map(
        filter(this.commonTeeth, (item) => item.number && !item.deficiency),
        (it) => it.number
      );
    },
    selectOne() {
      let list = filter(this.selectList, (item) =>
        [
          18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
        ].includes(item)
      );
      let leftTooth = filter(list, (item) => item >= 11 && item <= 18).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, (item) => item >= 21 && item <= 28).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    selectTow() {
      let list = filter(this.selectList, (item) =>
        [
          48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
        ].includes(item)
      );
      let leftTooth = filter(list, (item) => item >= 41 && item <= 48).sort(
        (a, b) => b - a
      );
      let rightTooth = filter(list, (item) => item >= 31 && item <= 38).sort(
        (a, b) => a - b
      );
      return [...leftTooth, ...rightTooth];
    },
    portImg() {
      return (
        {
          admin: "blue_active_img",
          clinic: "blue_active_img",
          irdt: "purple_active_img",
        }[""] || "yellow_active_img"
      );
    },
    missTeethList() {
      return map(
        filter(this.commonTeeth, (item) => item.deficiency),
        (it) => it.number
      );
    },
    commonTeethNew() {
      return this.commonTeeth || [];
    },
  },
  watch: {
    crowdTeethList: {
      handler(val) {
        if (this.setList) {
          this.teethList = val;
          this.setList = false;
        }
        if (val.length) {
          this.teethOne = map(this.teethOne, (item) => {
            let obj = item;
            each(val, (it) => {
              if (
                item.number === it.number &&
                !this.missTeethList.includes(it.number)
              ) {
                return (obj = extend({}, item, it));
              }
            });
            return obj;
          });
          this.teethTow = map(this.teethTow, (item) => {
            let obj = item;
            each(val, (it) => {
              if (
                item.number === it.number &&
                !this.missTeethList.includes(it.number)
              ) {
                return (obj = extend({}, item, it));
              }
            });
            return obj;
          });
        }
      },
      immediate: true,
    },
    commonTeethNew: {
      handler(newValue, old) {
        newValue = filter(newValue, (item) => !item.deficiency);
        old = filter(old, (item) => !item.deficiency);
        if (
          isArray(newValue) &&
          newValue.length &&
          isArray(old) &&
          old.length &&
          newValue.length < old.length
        ) {
          let list = map(newValue, (it) => it.number);
          let number;
          each(old, (item) => {
            if (!list.includes(item.number)) {
              number = item.number;
            }
          });
          each(this.teethKey, (item) => {
            this[item] = map(this[item], (it) => {
              if (
                (it.nextNumber && it.nextNumber === number) ||
                it.number === number
              ) {
                return pickBy(extend({}, it, { nextNumber: "", width: "" }));
              }
              return it;
            });
          });
        } else {
          let number, isClickDeficiency;
          let newDeficiencyList = map(
            filter(newValue, (it) => it.deficiency),
            (a) => a.number
          );
          let oldDeficiencyList = map(
            filter(old, (it) => it.deficiency),
            (a) => a.number
          );
          isClickDeficiency =
            newDeficiencyList.length > oldDeficiencyList.length;
          if (isClickDeficiency) {
            each(newDeficiencyList, (item) => {
              if (!oldDeficiencyList.includes(item)) {
                number = item;
              }
            });
          }
          each(this.teethKey, (item) => {
            this[item] = map(this[item], (it) => {
              if (
                (it.nextNumber && it.nextNumber === number) ||
                it.number === number
              ) {
                return pickBy(extend({}, it, { nextNumber: "", width: "" }));
              }
              return it;
            });
          });

          let list = {
            0: "selectOne",
            1: "selectTow",
          };
          each(this.teethKey, (item, index) => {
            this[item] = map(this[item], (it) => {
              if (it.nextNumber) {
                return {
                  ...it,
                  nextNumber: this.findAdjoinTooth(
                    it.number,
                    this[list[index]]
                  ),
                };
              }
              return it;
            });
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    find,
    selectTeethFn(number, list) {
      if (!list.includes(number)) return true;
      return list.includes(number) && !this.findAdjoinTooth(number, list);
    },
    popoverHide(index, type, item) {
      if (item && !item.width) {
        this.$refs[`${type}Popover`][index].doClose();
        this.teethList = filter(
          this.teethList,
          (it) => it.number !== item.number
        );
      }
    },
    teethClick(index, item, type) {
      if (map(this.teethList, (it) => it.number).includes(item.number)) {
        this.$refs[`${type}Popover`][index].doClose();
        this.teethList = filter(
          this.teethList,
          (it) => it.number !== item.number
        );
      } else {
        this.teethList.push(item);
        this.$refs[`${type}Popover`][index].doShow();
      }
    },
    findAdjoinTooth(number, list) {
      let index = list.indexOf(number);
      return list[index + 1];
    },
    disposeNumber({ key, value, list }) {
      if (value === "") return (key.input = "");
      key.width =
        isNumber(toNumber(value)) && toNumber(value) <= 25
          ? toNumber(value)
          : "";
      key.nextNumber = this.findAdjoinTooth(key.number, list);
      this.$emit(
        "updateInterval",
        filter([...this.teethOne, ...this.teethTow], (item) => item.width)
      );
    },
    resetNumber(item, list, index) {
      if (this.readOnly) return;
      if (item.width) {
        this[list][index].width = undefined;
      }
    },
    clearTeeth() {
      each(this.teethOne, (item) => {
        item.width = undefined;
      });
      each(this.teethTow, (item) => {
        item.width = undefined;
      });
    },
  },
  created() {
    this.setList = true;
  },
};
</script>

<style lang="scss" scoped>
.input_mm {
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
}
.wrap {
  width: 100%;
  .content {
    display: flex;
    justify-content: center;
    margin-bottom: 0.2rem;
    .teeth {
      display: flex;
      align-items: center;
      margin-left: 0.1rem;
      .interval_input {
        display: flex;
      }
      span {
        font-size: 0.16rem;
      }
      div {
        margin-left: 0.1rem;
        width: 0.25rem;
        height: 0.25rem;
        border: 0.01rem solid #898989;
        text-align: center;
        line-height: 0.4rem;
        border-radius: 0.05rem;
      }
    }
  }
  .detail {
    padding: 0.2rem;
    width: 100%;
    background-color: #58595a;
    margin: 0 auto;
  }
  .detail_old {
    color: $main_theme_color_333;
    background: #f4f4f4;
  }
}
.input_width {
  margin-top: 0.2rem;
  width: 2.3rem;
}
.active_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.yellow_active_img {
  background-image: url("./img/gb.png");
}
.blue_active_img {
  background-image: url("./img/blue_gb.png");
}
.purple_active_img {
  background-image: url("./img/purple_gb.png");
}
</style>