<template>
  <li class="preview_single">
    <div class="preview_s_left">
      <h5 :class="{ main_theme_color_333: isOldCase }" class="s_l_h5">
        <span class="s_l_icon" :class="'yellow_user'"></span>
        {{ $t("casesDetail.publicCases.casesUser.jbxx") }}
      </h5>
      <div class="s_l_box">
        <div :class="{ main_theme_color_333: isOldCase }" class="s_l_box_p">
          <p
            :style="language === 'en_US' ? 'width: 2.2rem' : ''"
            class="flex-y-c"
          >
            {{
              caseInfo.cureType === "F" && isNowEnv
                ? $t("casesDetail.casesDetail.ddxm")
                : $t("cases.createUser.hzmc")
            }}:
            <span style="width: 0.8rem" class="txt-ellipsis">{{
              userInfo.realName
            }}</span>
          </p>
          <span :class="language === 'en_US' ? 'ml10' : 'ml40'"
            >{{ $t("cases.createUser.xb") }}:
            {{
              userInfo.sex
                ? userInfo.sex === "1"
                  ? $t("casesDetail.publicCases.casesUser.n")
                  : $t("casesDetail.publicCases.casesUser.v")
                : "-"
            }}</span
          >
          <span :class="language === 'en_US' ? 'ml10' : 'ml40'"
            >{{ $t("cases.createUser.csrq") }}：{{ userInfo.birthdayStr }}</span
          >
        </div>
        <p :class="{ main_theme_color_333: isOldCase }" class="s_l_box_p">
          {{ $t("cases.cases.zs") }}：{{
            get(caseInfo, "clinicName") || get(caseInfo, "caseInfo.clinicName")
          }}
        </p>
      </div>
    </div>
    <div :class="{ preview_s_right_old: isOldCase }" class="preview_s_right">
      <div>
        {{
          caseInfo.cureType === "F" && isNowEnv
            ? $t("casesDetail.casesDetail.ddh")
            : $t("casesDetail.casesChildren.reviewPlan.blh")
        }}
        <input
          readonly
          id="casesNumber"
          class="preview_s_r_strong"
          :value="
            get(caseInfo, 'caseNumber') || get(caseInfo, 'caseInfo.caseNumber')
          "
        />
      </div>
      <span
        ref="copyCases"
        class="preview_s_r_icon"
        :class="'yellow_fz'"
        data-clipboard-action="copy"
        data-clipboard-target="#casesNumber"
        @click="copyCasesNumber"
      ></span>
    </div>
    <notifyMsgCm ref="notifyMsgCm" />
  </li>
</template>

<script>
import clipboard from "clipboard";
import { notifyMsg } from "common/js/util";
import { get, map } from "lodash";
import notifyMsgCm from "../cases-detail/view3d/notify/notifyMsg";
import { mapState } from "vuex";

export default {
  data() {
    return {
      copyCases: null,
    };
  },
  mounted() {
    if (this.$refs.copyCases) {
      this.copyCases = new clipboard(this.$refs.copyCases);
    }
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    queryPort() {
      return "";
    },
    isNowEnv() {
      return this.$route.query.token;
    },
  },
  components: {
    notifyMsgCm,
  },
  methods: {
    get,
    map,
    copyCasesNumber() {
      this.$refs["notifyMsgCm"].open({
        text: this.$t("casesDetail.publicCases.casesUser.fzcg"),
        type: "success",
      });
    },
  },
  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    caseInfo: {
      type: Object,
      default: () => ({}),
    },
    isOldCase: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.preview_single {
  display: flex;
  margin-bottom: 0.41rem;
}
.preview_s_left {
  .s_l_h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    font-size: 0.16rem;
    .s_l_icon {
      margin-right: 0.1rem;
      display: inline-block;
      width: 0.24rem;
      height: 0.25rem;
      background-size: 100% 100%;
    }
    .yellow_user {
      background-image: url("../../../common/imgs/3d/case_user_icon.png");
    }
    .blue_user {
      background-image: url("../../../common/imgs/user_data/blue_user.png");
    }
    .purple_user {
      background-image: url("../../../common/imgs/user_data/purple_user.png");
    }
  }
  .s_l_box {
    padding-left: 0.24rem;
    .s_l_box_p {
      display: flex;
      align-items: center;
      margin-bottom: 0.1rem;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        margin-right: 0.2rem;
      }
    }
    .s_l_con {
      display: flex;
      margin-bottom: 0.31rem;
      position: relative;
      .s_l_con_left {
        margin-right: 0.1rem;
        white-space: nowrap;
      }
      .s_l_con_right {
        flex: 1;
        .p {
          margin-bottom: 0.21rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .note_box {
          height: 0.6rem;
        }
        .s_l_con_note {
          position: absolute;
          left: 0;
          width: 100%;
          padding: 0.2rem 0.1rem;
          background-color: #f3f3f3;
        }
        .r_strong {
          font-weight: 600;
          margin-left: 0.1rem;
          color: #111;
        }
      }
      .s_l_con_teeth {
        padding-top: 0.4rem;
        padding-bottom: 0.2rem;
      }
    }
  }
  .pre_list {
    margin-bottom: 0.1rem;
    padding-left: 0.24rem;
    .s_l_txt {
      color: #666;
      margin-bottom: 0.12rem;
    }
    li {
      width: 3.14rem;
      height: 2rem;
      border-radius: 0.06rem;
      background-size: 100% 100%;
      margin-bottom: 0.2rem;
      &:last-child {
        margin-right: 0;
      }
    }
    .pic_list_space {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .pic_list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        margin-right: 0.3rem;
      }
    }
  }
}
.preview_s_right {
  width: 320px;
  height: 0.52rem;
  box-sizing: border-box;
  background-color: var(--darkColor);
  // background: #58595a;
  border-radius: 0.04rem;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 60px;
  margin-top: 40px;
  .preview_s_r_strong {
    width: 1.2rem;
    margin-left: 0.1rem;
    margin-right: 0.09rem;
  }
  .preview_s_r_icon {
    display: inline-block;
    width: 24px;
    height: 25px;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .yellow_fz {
    background-image: url("../../../common/imgs/3d/case_user_plus.png");
  }
  .blue_fz {
    background-image: url("../../../common/imgs/user_data/blue_fz.png");
  }
  .purple_fz {
    background-image: url("../../../common/imgs/user_data/purple_fz.png");
  }
}
.preview_s_right_old {
  color: $main_theme_color_333;
  background: #f4f4f4;
}
</style>
