<template>
  <li class="preview_single">
    <div class="preview_s_left">
      <h5 :class="{ main_theme_color_333: isOldCase }" class="s_l_h5">
        <span class="s_l_icon"></span>
        {{ $t("casesDetail.publicCases.casesDiagnosis.zdyq") }}
      </h5>
      <div class="s_l_box">
        <template v-for="(item, index) in casesData">
          <div v-if="!item.isHide" class="s_l_con" :key="index">
            <div :style="
              language === 'en_US'
                ? 'min-width: 2.4rem !important'
                : 'min-width: 1.7rem !important'
            " class="s_l_con_left txt-ellipsis2">
              {{ item.fIndex ? item.fIndex + ". " : ""
              }}{{ item.title ? item.title + ": " : "" }}
            </div>
            <div class="s_l_con_right">
              <div class="s_l_con_right_p" v-for="(pItem, pIndex) in item.pList" :class="{
                dib: pItem.dib,
                [pItem.parentName]: pItem.parentName,
              }" :key="pIndex">
                <p class="fz16" v-if="pItem.value">
                  {{ details[pItem.filed] }}
                </p>
                <p v-if="
                  pItem.onlyHeaderText &&
                  pItem.isShow &&
                  isShowHeaderText(pItem.childOptions)
                " class="c-white fz16">
                  {{ pItem.superiorText }}
                </p>
                <template v-if="
                  +item.fIndex === 8 &&
                  !isIRDT &&
                  ['A', 'E', 'I'].includes(productType)
                ">
                  <div :class="{
                    main_theme_color_333: isOldCase,
                    'r_strong sublevel': !pItem.secondLevel,
                  }" v-if="details[pItem.filed]">
                    <div class="">
                      {{ pItem.secondLevel ? pItem.secondLevel + ":" : "" }}
                      {{
                        pItem.secondLevel
                        ? disposeStair(pItem.filed, pItem.inquireKey)
                        : ""
                      }}
                    </div>
                    <div v-if="details[pItem.filed] && pItem.threeOptions">
                      <div v-html="i" class="" v-for="i in disposeSagittal({
                        inquireKey: pItem.inquireKey,
                        key: pItem.filed,
                        threeOptions: pItem.threeOptions,
                      })"></div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <template v-if="
                    (+item.fIndex === 10 &&
                      pItem.sublevel &&
                      ['B', 'G', 'H'].includes(productType)
                      ? split(details[pItem.filed], ',').length > 1
                        ? true
                        : details[pItem.filed] === pItem.differentiateKey
                      : true) ||
                    [
                      'upHorizontalRelationshipValue',
                      'downHorizontalRelationshipValue',
                    ].includes(pItem.describeInputKey)
                  ">
                    <span v-if="
                      (pItem.type !== 'note' && details[pItem.filed]) ||
                      pItem.identification
                    " :class="{
  sublevel:
    pItem.sublevel &&
    details[pItem.filed].includes(pItem.differentiateKey),
  dib: pItem.identification,
  main_theme_color_333: isOldCase,
  sublevel_old:
    isOldCase &&
    pItem.sublevel &&
    details[pItem.filed].includes(pItem.differentiateKey),
}" class="r_strong">
                      <span :class="pItem.className" v-if="
                        pItem.identification &&
                        isShowHeaderText(pItem.childOptions)
                      ">
                        {{ pItem.identification }}
                      </span>

                      <span v-if="pItem.secondLevel" class="mb1rem">
                        {{ pItem.secondLevel }}
                      </span>
                      <span :class="pItem.className" v-if="pItem.superiorText">
                        {{ pItem.superiorText }}：
                      </span>

                      <template v-if="!pItem.identification">
                        <div :class="pItem.answerClass" class="dib">
                          <div v-if="pItem.filed === 'gapCorrect'">
                            <div class="" v-for="(p, px) in gapCorrectList" :key="px">
                              {{ resolutionDisposeText(pItem.inquireKey, p) }}
                            </div>
                          </div>
                          <span class="" v-html="
                            disposeText({
                              inquireKey: pItem.inquireKey,
                              inquireKeys: pItem.inquireKeys,
                              key: pItem.filed,
                              differentiateKey: pItem.differentiateKey,
                              threeOptions: pItem.threeOptions,
                              parentText: pItem.parentText,
                              describeInputKey: pItem.describeInputKey,
                            })
                          " v-else>
                          </span>
                          <span v-if="pItem.describeInputKey">
                            {{
                              details[pItem.describeInputKey]
                              ? (![
                                "downjawMiddleLineNote",
                                "upjawMiddleLineNote",
                              ].includes(pItem.filed)
                                ? " - "
                                : "") + details[pItem.describeInputKey]
                              : ""
                            }}
                            <!--数组内的值为提交的选项key-->
                            {{
                              [
                                "specify",
                                "improve_middle_move_left_right",
                                "improve_middle_move_left",
                              ].includes(details[pItem.filed]) &&
                              pItem.isShowMM &&
                              details[pItem.describeInputKey]
                              ? "mm"
                              : ""
                            }}
                          </span>
                        </div>
                      </template>
                    </span>
                  </template>

                  <div v-if="pItem.type === 'note' && details[pItem.filed]" :class="{ s_l_con_note_note: isOldCase }"
                    class="s_l_con_note wsp" :style="
                      index === casesData.length - 1 ? { top: '0.3rem' } : {}
                    ">
                    <span :class="{ main_theme_color_333: isOldCase }" class="r_strong">
                      {{ details[pItem.filed] }} {{ pItem.input ? "mm" : "" }}
                    </span>
                  </div>

                  <ChildrenTeeth v-if="
                    (pItem.key === 'teeth' &&
                      (details[pItem.filed]
                        ? JSON.parse(details[pItem.filed])
                        : '')) ||
                    pItem.showTeeth
                  " :commonTeeth="commonTeeth" :readOnly="true" :patientType="pItem.type" class="mb40 mt40"
                    :teethType="pItem.teethType" />

                  <IntervalCorrect class="mt10" v-if="
                    pItem.key === 'interval' &&
                    ((details[pItem.filed] &&
                      isArray(details[pItem.filed].split(',')) &&
                      details[pItem.filed]
                        .split(',')
                        .includes('reserved_gap')) ||
                      pItem.filed === 'teeth')
                  " :readOnly="true" ref="intervalCorrect" :commonTeeth="commonTeeth" :productType="productType"
                    :uploadIntervalList="uploadIntervalList" />

                  <AdjoinDislodge class="mt20" :isOldCase="isOldCase" v-if="
                    pItem.key === 'adjoin' &&
                    get(details, 'crowdCorrectSide') === 'chooseable'
                  " :productType="productType" ref="adjoinDislodge" :readOnly="true"
                    :crowdTeethList="crowdTeethList" />

                  <VeneerTeeth v-if="pItem.veneer === 'veneer' && pItem.showVeneer" :commonTeeth="veneerList"
                    :readOnly="true" :patientType="pItem.type" class="mb40 mt40" :teethType="pItem.teethType" />
                  <YysdTeeth v-if="
                    pItem.filed === 'whetherToPrepareTeeth'
                      ? get(details, 'whetherToPrepareTeeth') === 'true' &&
                      pItem.showYysd
                      : pItem.showYysd
                  " :commonTeeth="commonTeeth" :isDark="isDark" :readOnly="true" :teethType="pItem.teethType"
                    :childKey="pItem.childKey" />

                  <ReserveDinterval class="mt20" :readOnly="true" :isOldCase="isOldCase"
                    v-if="pItem.key === 'reserve' && details[pItem.filed]" ref="intervalCorrect"
                    :crowdTeethList="uploadIntervalList" :commonTeeth="commonTeeth" />
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </li>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import { getDictAllList } from "common/api/public";
import ChildrenTeeth from "../../../components/treatment-table/children-teeth-copy/index";
import IntervalCorrect from "../../../components/treatment-table/interval-correct-copy/index";
import AdjoinDislodge from "../children-adjoin-dislodge-copy/index";
import VeneerTeeth from "../../../components/treatment-table/veneers-teeth/index.vue";
import YysdTeeth from "../../../components/treatment-table/yysd-teeth/yysd-teeth.vue";
import {
  get,
  pickBy,
  join,
  map,
  flattenDeep,
  filter,
  each,
  extend,
  isArray,
  some,
  split,
} from "lodash";
import ReserveDinterval from "../../../components/treatment-table/reserve-dinterval-copy/index";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: true,
      diaKeyObj: {},
    };
  },
  props: {
    casesData: {
      // 渲染列表
      type: Array,
      default: () => [],
    },
    isDark: {
      required: false,
    },
    details: {
      //详情
      type: Object,
      default: () => ({}),
    },
    commonTeeth: {
      //牙齿
      type: Array,
    },
    uploadIntervalList: {
      //间隙的矫治
      type: Array,
    },
    crowdTeethList: {
      //邻面去釉
      type: Array,
    },
    productType: {
      type: String,
    },
    isOldCase: {
      type: Boolean,
      default: false,
    },
    veneerList: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    queryPort() {
      return "";
    },
    isIRDT() {
      return sessionStorage.getItem("needRTD") === "1";
    },
    gapCorrectList() {
      let list = this.details["gapCorrect"].split(",") || [];
      if (list.length > 1) {
        if (list[0] === "reserved_gap") {
          let li = [];
          each(list, (item) => {
            li.unshift(item);
          });
          return li;
        }
      }
      return list;
    },
  },
  methods: {
    join,
    map,
    flattenDeep,
    filter,
    get,
    isArray,
    split,
    isShowHeaderText(keyList) {
      return some(keyList, (item) => this.details[item]);
    },
    disposeText({
      key,
      inquireKey,
      inquireKeys,
      threeOptions,
      parentText,
      describeInputKey,
      differentiateKey,
    }) {
      let keyList = this.details[key].split(",");
      if (inquireKeys) {
        let returnList = [];
        each(keyList, (item) => {
          each(inquireKeys, (inq) => {
            each(this.diaKeyObj[inq], (dia) => {
              if (item === dia.key) {
                returnList.push(
                  dia.value +
                  (dia.key === "front_tooth_back"
                    ? ` <span class="reset_child_copy">${{
                      correct_front_reverse: this.$t(
                        "cases.createDiagnosis.qubtjjl"
                      ),
                    }[inq] || ""
                    }<span>`
                    : "")
                );
              }
            });
          });
        });
        returnList = Array.from(new Set(returnList));
        return (
          `${parentText ? parentText + " - " : ""}` + returnList.join("、")
        );
      } else {
        let list = map(
          filter(
            this.diaKeyObj[inquireKey],
            (item) => inquireKey === item.parentKey
          ),
          (it) => {
            return pickBy({
              value: it.value,
              key: it.key,
              threeOptions: threeOptions ? threeOptions : "",
            });
          }
        );
        let returnList = [];

        if (list.length) {
          each(list, (item) => {
            each(keyList, (it) => {
              if (item.key === it) {
                //10
                if (inquireKey === "improve_molar") {
                  let str = item.key === differentiateKey ? item.value : "";
                  returnList.push(str);
                  return (returnList = filter(returnList));
                }
                //11横向关系
                if (
                  [
                    "down_horizontal_expansion",
                    "up_horizontal_expansion",
                  ].includes(inquireKey) &&
                  item.key === "specify"
                ) {
                  return returnList.push(
                    item.value +
                    (this.details[describeInputKey]
                      ? ` - ${this.$t("cases.createDiagnosis.total")}`
                      : "")
                  );
                }

                // 处理多个二级选项下有三级选项
                if (
                  item.threeOptions &&
                  [
                    "far_grind",
                    "bit_jump",
                    "tooth_extraction",
                    "extend_front_tooth",
                    "press_front",
                    "press_front_rise_back",
                  ].includes(item.key)
                ) {
                  let str = filter(
                    map(item.threeOptions, (three) => {
                      if (item.key === three.parentKey) {
                        if (["tooth_extraction"].includes(item.key)) {
                          let str = map(
                            filter(this.diaKeyObj[three.inquireKey], (i) => {
                              return (
                                this.details[three.key] &&
                                isArray(this.details[three.key].split(",")) &&
                                this.details[three.key]
                                  .split(",")
                                  .includes(i.key)
                              );
                            }),
                            (j) => j.value
                          ).join();
                          return `${item.value} ${str ? "-" + str : ""}`;
                        }
                        if (this.details[three.key]) {
                          const resultKey = this.details[three.key].split(",");
                          let childKeys = [];
                          map(
                            this.diaKeyObj[three.inquireKey],
                            (i) =>
                              resultKey.includes(i.key) && childKeys.push(i.value)
                          );
                          //目前 多个二级选项下有三级选项  只有一个
                          return `${item.value} ${this.details[three.key]
                              ? `- ` +
                              `${childKeys.join("、")}` +
                              `${{
                                rightSagittalRelationFar: this.$t(
                                  "cases.createDiagnosis.yjdjzzyymy"
                                ),
                                leftSagittalRelationFar: this.$t(
                                  "cases.createDiagnosis.yjdjzzyymy"
                                ),
                              }[three.key] || ""
                              }`
                              : ""
                            }`;
                        }
                      }
                    })
                  ).join();
                  return returnList.push(str);
                }
                returnList.push(item.value);
              }
            });
          });
          if (
            [
              "overbiteInfoDeep",
              "overbiteInfoOpen",
              "rightSagittalRelationNote",
              "leftSagittalRelationNote",
            ].includes(key)
          ) {
            let haveThree = [
              "press_front_rise_back",
              "press_front",
              "extend_front_tooth",
            ].includes(keyList[0]);
            let text = "";
            each(returnList, (it, itx) => {
              text +=
                it +
                (returnList.length > 1
                  ? haveThree && itx === 0
                    ? ","
                    : itx < returnList.length - 1
                      ? "、"
                      : ""
                  : "、");
            });
            return text;
          } else {
            return returnList.join("、");
          }
        }
      }
    },
    resolutionDisposeText(inquireKey, key) {
      let str = "";
      each(this.diaKeyObj[inquireKey], (item) => {
        if (item.key === key) {
          str = item.value;
        }
      });
      return str;
    },
    disposeStair(key, inquireKey) {
      let list = filter(
        this.diaKeyObj[inquireKey],
        (dia) => inquireKey === dia.parentKey
      );
      let keyList = this.details[key].split(",");
      let str = "";
      if (list.length) {
        each(list, (item) => {
          each(keyList, (it) => {
            if (item.key === it) {
              str = item.value;
            }
          });
        });
        return str;
      }
    },
    disposeSagittal({ key, inquireKey, inquireKeys, threeOptions }) {
      let keyList = this.details[key].split(",");
      let list = map(
        filter(
          this.diaKeyObj[inquireKey],
          (item) => inquireKey === item.parentKey
        ),
        (it) => {
          return pickBy({
            value: it.value,
            key: it.key,
            threeOptions: threeOptions ? threeOptions : "",
          });
        }
      );
      let returnList = [];
      if (list.length) {
        each(list, (item) => {
          each(keyList, (it) => {
            if (item.key === it) {
              // 处理多个二级选项下有三级选项
              if (
                item.threeOptions &&
                [
                  "far_grind",
                  "bit_jump",
                  "tooth_extraction",
                  "extend_front_tooth",
                  "press_front",
                  "press_front_rise_back",
                ].includes(item.key)
              ) {
                let str = filter(
                  map(item.threeOptions, (three) => {
                    if (item.key === three.parentKey) {
                      if (["tooth_extraction"].includes(item.key)) {
                        let str = map(
                          filter(this.diaKeyObj[three.inquireKey], (i) => {
                            return (
                              this.details[three.key] &&
                              isArray(this.details[three.key].split("、")) &&
                              this.details[three.key].split(",").includes(i.key)
                            );
                          }),
                          (j) => j.value
                        ).join("、");
                        return `${item.value} ${str ? "- " + str : ""}`;
                      }

                      return (
                        `${item.value} ${this.details[three.key]
                          ? `- ` +
                          `${map(
                            this.diaKeyObj[three.inquireKey],
                            (i) => i.value
                          ).join("、")}`
                          : ""
                        }` +
                        `<span class="c-red">${item.key === "far_grind"
                          ? this.$t("cases.createDiagnosis.yjdjzzyymy")
                          : ""
                        } </span>`
                      );
                    }
                  })
                ).join();
                return returnList.push(str);
              }
              returnList.push(item.value);
            }
          });
        });
        return returnList;
      }
    },
  },
  created() {
    getDictAllList([
      "tooth_problem",
      "tooth_problem_i",
      "underjaw_abnormal_flag",
      "periodontal_disease",
      "tooth_problem_b",
      "to_correct_tooth_upjaw_b",
      "to_correct_tooth_downjaw_b",
      "left_sagittal_relation",
      "right_sagittal_relation",
      "jaw_problem_b",
      "tooth_problem_two",
      "jaw_problem_two",
      "bad_habits",
      "sagittal_relation",
      "improve_molar",
      "dentition",
      "to_correct_tooth_jaw",
      "up_horizontal_relationship",
      "down_horizontal_relationship",
      "up_horizontal_expansion",
      "down_horizontal_expansion",
      "cover_info",
      "overbite_info",
      "correct_open_b",
      "correct_deep_cover_b",
      "updown_jaw",
      "front_tooth_info_b",
      "correct_front_reverse",
      "back_tooth_info",
      "back_tooth_info_correct_b",
      "choose_option_b",
      "angle_classification",
      "sagittal_maxillomandibular_classification",
      "clinic_info",
      "middle_line",
      "improve_middle_move",
      "to_correct_tooth_downjaw",
      "tooth_extraction",
      "choose_option",
      "choose_option_b",
      "upjaw_far",
      "downjaw_far",
      "non_moveable_teeth",
      "not_available_for_attachments",
      "tooth_problem_c",
      "crowd_correct",
      "tooth_problem_two_c",
      "tooth_problem",
      "jaw_problem",
      "jaw_problem_two",
      "tooth_problem_two",
      "angle_classification",
      "sagittal_maxillomandibular_classification",
      "clinic_info",
      "bad_habits",
      "dentition",
      "to_correct_tooth_jaw",
      "to_correct_tooth_upjaw",
      "to_correct_tooth_downjaw",
      "non_moveable_teeth",
      "not_available_for_attachments",
      "sagittal_relation",
      "left_sagittal_relation",
      "right_sagittal_relation",
      "improve_tip",
      "precision_cut",
      "updown_jaw_gap_correct",
      "gap_correct",
      "underjaw_abnormal_flag",
      "grow_nail_flag",
      "grow_nail_position",
      "grow_nail_purpose",
      "improve_molar",
      "up_horizontal_relationship",
      "down_horizontal_relationship",
      "up_horizontal_expansion",
      "down_horizontal_expansion",
      "cover_info",
      "overbite_info",
      "correct_open",
      "correct_deep_cover",
      "updown_jaw",
      "front_tooth_info",
      "correct_front_reverse",
      "back_tooth_info",
      "back_tooth_info_correct",
      "correct_front_reverse_two",
      "correct_front_opposite",
      "choose_option_b",
      "middle_line",
      "improve_middle_move",
      "to_correct_tooth_downjaw",
      "tooth_extraction_options",
      "choose_option",
      "choose_option_b",
      "upjaw_far",
      "downjaw_far",
      "non_moveable_teeth",
      "need_passive_appliance",
      "erupted_compensate",
      "accept_wisdom_teeth_extraction",
      "accept_premolars_extraction",
      "accept_decayed_teeth_extraction",
      "accept_ipr",
      "undergo_teeth_restoration",
      "implant_anchorage_placement",
      "cbct_photography",
      "orthodontics_treatment_before",
      "original_teeth_color",
      "target_color",
      "surface_texture",
      "design_request_one",
      "design_request_two",
      "clinic_info_f",
      "occlusal_relationship",
      "adjacent_gap",
      "tooth_problem_three",
      "original_teeth_color_f",
      "ivoclar",
      "vita",
      "target_color",
      "target_type",
      "target_transparency",
      "material_type",
      "whether_to_prepare_teeth",
      "veneer_thickness_requirements",
      "surface_texture",
      "design_request_one_f",
      "design_request_two_f",
      "special_instructions",
      "silicone_rubber_model",
    ])
      .then((data) => {
        this.isLoading = false;
        let obj = {};
        data.forEach((item) => {
          obj[item.parentKey] = filter(
            data,
            (it) => it.parentKey === item.parentKey
          );
        });
        this.diaKeyObj = obj;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  components: {
    FullLoading,
    ChildrenTeeth,
    IntervalCorrect,
    AdjoinDislodge,
    ReserveDinterval,
    VeneerTeeth,
    YysdTeeth,
  },
};
</script>

<style scoped lang="scss">
.preview_single {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.51rem;
}

.preview_s_left {
  width: 100%;

  .s_l_h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0.29rem;

    .s_l_icon {
      margin-right: 0.1rem;
      display: inline-block;
      width: 0.15rem;
      height: 0.16rem;
      background-size: 100% 100%;
    }

    .yellow_icon {
      background-image: url("/static/images/createCases/preview_diag.png");
    }

    .blue_icon {
      background-image: url("../../../common/imgs/user_data/blue_diag.png");
    }

    .purple_icon {
      background-image: url("../../../common/imgs/user_data/purple_diag.png");
    }
  }

  .s_l_box {
    padding-left: 0.24rem;

    .s_l_box_p {
      height: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 0.21rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-right: 0.2rem;
      }
    }

    .s_l_con {
      display: flex;
      margin-bottom: 0.21rem;
      position: relative;
      line-height: .2rem;

      .s_l_con_left {
        width: 1.7rem;
        color: #999999;
      }

      .s_l_con_right {
        flex: 1;
        margin-left: 0.2rem;

        .s_l_con_right_p {
          width: 100%;
          margin-bottom: 0.11rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .s_l_con_note {
          width: 100%;
          word-wrap: break-word;
          word-break: break-all;
          padding: 0.2rem 0.1rem;
          background-color: #58595a;
          line-height: 0.2rem;
          /*margin-left: 0.1rem;*/
          border-radius: 4px;
        }

        .s_l_con_note_note {
          color: $main_theme_color_333;
          background: #f4f4f4;
        }

        .r_strong {
          /*margin-left: 0.1rem;*/
          position: relative;
        }
      }
    }
  }

  .pre_list {
    margin-bottom: 0.1rem;
    padding-left: 0.24rem;

    .s_l_txt {
      color: #666;
      margin-bottom: 0.12rem;
    }

    li {
      width: 3.14rem;
      height: 2rem;
      border-radius: 0.06rem;
      background-size: 100% 100%;
      margin-bottom: 0.2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .pic_list_space {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .pic_list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin-right: 0.3rem;
      }
    }
  }
}

.preview_s_right {
  width: 6.3rem;
  height: 0.85rem;
  display: flex;
  align-items: center;
  padding-left: 0.28rem;
  box-sizing: border-box;
  background-color: #f3f3f3;
  border-radius: 0.06rem;

  .preview_s_r_strong {
    font-size: 0.2rem;
    margin-left: 0.1rem;
    margin-right: 0.09rem;
  }

  .preview_s_r_icon {
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    background-color: yellow;
    background-size: 100% 100%;
    cursor: pointer;
  }
}

.sublevel {
  box-sizing: border-box;
  width: 100%;
  padding: 0.2rem;
  display: inline-block;
  background-color: #58595a;
  border-radius: 4px;
}

.sublevel_old {
  color: $main_theme_color_333;
  background: #f4f4f4;
}

// 15
.header_text {
  width: 0.5rem;
  position: absolute;
  top: 0.12rem;
}

.body_up {
  transform: translateY(-0.34rem);
}
</style>
