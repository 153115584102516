<template>
  <transition name="slide-fade">
    <div v-show="dia" class="notify_wrap">
      <div class="notify_msg">
        <svg class="icon notify_msg_icon" aria-hidden="true">
          <use :xlink:href="`#${ {
            success: 'icon-chenggong',
            error: 'icon-cuowu',
            warning: 'icon-jinggao',
          }[type]}`"></use>
        </svg>
        <p class="main_theme_color_333">{{text}}</p>
        <svg @click="deleteNumber"
             class="icon close curp" aria-hidden="true">
          <use xlink:href="#icon-guanbi_xuantingdianji"></use>
        </svg>
        <!--<img @click="deleteNumber" class="close" src="../../../../../common/imgs/3d/icon_close.png" alt="">-->
      </div>
    </div>
  </transition>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    data() {
      return {
        number: 0,
        text: '',
        dia: false,
        type: ''

      };
    },
    props:{
      isOldCase:{
        type: Boolean,
        default: false
      }
    },
    watch:{
      dia(val){
        if(val){
          setTimeout(() => {
            this.dia = false;
          }, 3000)
        }
      }
    },
    computed: {
      ...mapGetters({}),
    },

    methods: {
      ...mapActions({}),
      open({text,type}){
        this.dia = true;
        this.text = text;
        this.type = type;
        return Promise.resolve();
      },
      deleteNumber(){
        this.dia = false;
      }
    },

    created() {
    }
  }
</script>

<style lang="scss" scoped>
  .notify_wrap{
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 9999;
    margin-top: 20px;
    .notify_msg{
      display: flex;
      align-items: flex-start;
      width: 400px;
      min-height: 72px;
      background-color: #fff;
      color: $main_theme_color_333;
      border: 1px solid white;
      box-shadow: 0 0 16px 0 rgba(51, 51, 51, 0.12);
      border-radius: 8px;
      padding: 18px;
      margin-bottom: 10px;
      position: relative;
      p{
        width: 2.8rem;
        line-height: 25px;
        margin-left: 10px;
      }
      .close{
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
    }
    .notify_msg_icon{
      width: 24px;
      height: 24px;
    }
  }
</style>