var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[(!['A', 'E', 'I'].includes(_vm.productType))?_c('div',{staticClass:"content"},_vm._l((_vm.teethOne),function(item,index){
var _obj;
return _c('div',{key:index,staticClass:"teeth",on:{"click":function($event){return _vm.teethClick(index, item, 'one')}}},[_c('span',{staticClass:"tooth-fdi",class:{ main_theme_color_333: _vm.isOldCase }},[_vm._v(_vm._s(item.number))]),_c('el-popover',{ref:"onePopover",refInFor:true,attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectOne),"placement":"bottom","trigger":"click"},on:{"after-leave":function($event){return _vm.popoverHide(index, 'one', item)}}},[_c('div',{staticClass:"flex-y-c"},[_c('el-select',{staticStyle:{"width":"2.2rem"},attrs:{"size":"medium","placeholder":_vm.$t('cases.createDiagnosis.controlgap')},on:{"change":function (val) { return _vm.selectChange(val, item, 'teethOne', _vm.selectOne); }},model:{value:(item.spacingType),callback:function ($$v) {_vm.$set(item, "spacingType", $$v)},expression:"item.spacingType"}},_vm._l((_vm.options),function(it){return _c('el-option',{key:it.value,attrs:{"label":it.label,"value":it.value}})}),1),_c('div',{staticClass:"ml20 wc_btn",class:{ active_btn: item['maintain'] },on:{"click":function($event){return _vm.selectChange('maintain', item, 'teethOne', _vm.selectOne)}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createDiagnosis.wcjx"))+" ")])],1),_c('div',{staticClass:"interval_input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.spacingType === '1' || item.spacingType === '2'),expression:"item.spacingType === '1' || item.spacingType === '2'"}],staticClass:"posr"},[_c('el-input',{staticClass:"input_width",attrs:{"value":item.width,"placeholder":item.spacingType == '1'
                  ? _vm.$t('cases.createDiagnosis.kdjxz')
                  : _vm.$t('cases.createDiagnosis.sxjxz')},on:{"change":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })},"input":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })}},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}}),_c('span',{staticClass:"input_mm"},[_vm._v("mm")])],1)]),(index !== _vm.teethOne.length - 1)?_c('div',{staticClass:"com_img",class:( _obj = {}, _obj[{
              irdt: 'purple_kd',
              admin: 'blue_kd',
              clinic: 'blue_kd',
            }[_vm.queryPort] || 'big_kd'] = item['spacingType'] === '1', _obj[{
              irdt: 'purple_sx',
              admin: 'blue_sx',
              clinic: 'blue_sx',
            }[_vm.queryPort] || 'big_sx'] = item['spacingType'] === '2', _obj[{
              irdt: 'purple_gb',
              admin: 'blue_gb',
              clinic: 'blue_gb',
            }[_vm.queryPort] || 'big_gb'] = item['spacingType'] === '3', _obj[{
              irdt: 'purple_wc',
              admin: 'blue_wc',
              clinic: 'blue_wc',
            }[_vm.queryPort] || 'big_wc'] = item['maintain'], _obj['curp hvr-bd'] =  _vm.selectTeethFn(item.number, _vm.selectOne), _obj ),attrs:{"slot":"reference"},on:{"click":function($event){return _vm.resetNumber(item, 'teethOne', index)}},slot:"reference"}):_vm._e()])],1)}),0):_vm._e(),(!_vm.productType || ['B', 'G', 'H', 'I'].includes(_vm.productType))?_c('div',{staticClass:"content"},_vm._l((_vm.teethTow),function(item,index){
          var _obj;
return _c('div',{key:index,staticClass:"teeth",on:{"click":function($event){return _vm.teethClick(index, item, 'two')}}},[_c('span',{staticClass:"tooth-fdi",class:{ main_theme_color_333: _vm.isOldCase }},[_vm._v(_vm._s(item.number))]),_c('el-popover',{ref:"twoPopover",refInFor:true,attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectTow),"placement":"bottom","trigger":"click"},on:{"after-leave":function($event){return _vm.popoverHide(index, 'two', item)}}},[_c('div',{staticClass:"flex-y-c"},[_c('el-select',{staticStyle:{"width":"2.2rem"},attrs:{"size":"medium","placeholder":_vm.$t('cases.createDiagnosis.controlgap')},on:{"change":function (val) { return _vm.selectChange(val, item, 'teethTow', _vm.selectTow); }},model:{value:(item.spacingType),callback:function ($$v) {_vm.$set(item, "spacingType", $$v)},expression:"item.spacingType"}},_vm._l((_vm.options),function(it){return _c('el-option',{key:it.value,attrs:{"label":it.label,"value":it.value}})}),1),_c('div',{staticClass:"ml20 wc_btn",class:{ active_btn: item['maintain'] },on:{"click":function($event){return _vm.selectChange('maintain', item, 'teethTow', _vm.selectTow)}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createDiagnosis.wcjx"))+" ")])],1),_c('div',{staticClass:"interval_input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.spacingType === '1' || item.spacingType === '2'),expression:"item.spacingType === '1' || item.spacingType === '2'"}],staticClass:"posr"},[_c('el-input',{staticClass:"input_width",attrs:{"value":item.width,"placeholder":item.spacingType == '1'
                  ? _vm.$t('cases.createDiagnosis.kdjxz')
                  : _vm.$t('cases.createDiagnosis.sxjxz')},on:{"change":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })},"input":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })}}}),_c('span',{staticClass:"input_mm"},[_vm._v("mm")])],1)]),(index !== _vm.teethTow.length - 1)?_c('div',{staticClass:"com_img",class:( _obj = {}, _obj[{
              irdt: 'purple_kd',
              admin: 'blue_kd',
              clinic: 'blue_kd',
            }[_vm.queryPort] || 'big_kd'] = item['spacingType'] === '1', _obj[{
              irdt: 'purple_sx',
              admin: 'blue_sx',
              clinic: 'blue_sx',
            }[_vm.queryPort] || 'big_sx'] = item['spacingType'] === '2', _obj[{
              irdt: 'purple_wc',
              admin: 'blue_wc',
              clinic: 'blue_wc',
            }[_vm.queryPort] || 'big_wc'] = item['maintain'], _obj[{
              irdt: 'purple_gb',
              admin: 'blue_gb',
              clinic: 'blue_gb',
            }[_vm.queryPort] || 'big_gb'] = item['spacingType'] === '3', _obj['curp hvr-bd'] =  _vm.selectTeethFn(item.number, _vm.selectTow), _obj ),attrs:{"slot":"reference"},on:{"click":function($event){return _vm.resetNumber(item, 'teethTow', index)}},slot:"reference"}):_vm._e()])],1)}),0):_vm._e(),(!_vm.productType ||  ['B', 'G', 'H', 'I'].includes(_vm.productType))?_c('div',{staticClass:"content"},_vm._l((_vm.teethThree),function(item,index){
          var _obj;
return _c('div',{key:index,staticClass:"teeth",on:{"click":function($event){return _vm.teethClick(index, item, 'three')}}},[_c('span',{staticClass:"tooth-fdi",class:{ main_theme_color_333: _vm.isOldCase }},[_vm._v(_vm._s(item.number))]),_c('el-popover',{ref:"threePopover",refInFor:true,attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectThree),"placement":"bottom","trigger":"click"},on:{"after-leave":function($event){return _vm.popoverHide(index, 'three', item)}}},[_c('div',{staticClass:"flex-y-c"},[_c('el-select',{staticStyle:{"width":"2.2rem"},attrs:{"size":"medium","placeholder":_vm.$t('cases.createDiagnosis.controlgap')},on:{"change":function (val) { return _vm.selectChange(val, item, 'teethThree', _vm.selectThree); }},model:{value:(item.spacingType),callback:function ($$v) {_vm.$set(item, "spacingType", $$v)},expression:"item.spacingType"}},_vm._l((_vm.options),function(it){return _c('el-option',{key:it.value,attrs:{"label":it.label,"value":it.value}})}),1),_c('div',{staticClass:"ml20 wc_btn",class:{ active_btn: item['maintain'] },on:{"click":function($event){return _vm.selectChange('maintain', item, 'teethThree', _vm.selectThree)}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createDiagnosis.wcjx"))+" ")])],1),_c('div',{staticClass:"interval_input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.spacingType === '1' || item.spacingType === '2'),expression:"item.spacingType === '1' || item.spacingType === '2'"}],staticClass:"posr"},[_c('el-input',{staticClass:"input_width",attrs:{"value":item.width,"placeholder":item.spacingType === '1'
                  ? _vm.$t('cases.createDiagnosis.kdjxz')
                  : _vm.$t('cases.createDiagnosis.sxjxz')},on:{"change":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })},"input":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })}}}),_c('span',{staticClass:"input_mm"},[_vm._v("mm")])],1)]),(index !== _vm.teethThree.length - 1)?_c('div',{staticClass:"com_img",class:( _obj = {}, _obj[{
              irdt: 'purple_kd',
              admin: 'blue_kd',
              clinic: 'blue_kd',
            }[_vm.queryPort] || 'big_kd'] = item['spacingType'] === '1', _obj[{
              irdt: 'purple_sx',
              admin: 'blue_sx',
              clinic: 'blue_sx',
            }[_vm.queryPort] || 'big_sx'] = item['spacingType'] === '2', _obj[{
              irdt: 'purple_wc',
              admin: 'blue_wc',
              clinic: 'blue_wc',
            }[_vm.queryPort] || 'big_wc'] = item['maintain'], _obj[{
              irdt: 'purple_gb',
              admin: 'blue_gb',
              clinic: 'blue_gb',
            }[_vm.queryPort] || 'big_gb'] = item['spacingType'] === '3', _obj['curp hvr-bd'] =  _vm.selectTeethFn(item.number, _vm.selectThree), _obj ),attrs:{"slot":"reference"},on:{"click":function($event){return _vm.resetNumber(item, 'teethThree', index)}},slot:"reference"}):_vm._e()])],1)}),0):_vm._e(),(!['A', 'E', 'I'].includes(_vm.productType))?_c('div',{staticClass:"content"},_vm._l((_vm.teethFour),function(item,index){
          var _obj;
return _c('div',{key:index,staticClass:"teeth",on:{"click":function($event){return _vm.teethClick(index, item, 'four')}}},[_c('span',{staticClass:"tooth-fdi",class:{ main_theme_color_333: _vm.isOldCase }},[_vm._v(_vm._s(item.number))]),_c('el-popover',{ref:"fourPopover",refInFor:true,attrs:{"disabled":_vm.readOnly || !_vm.selectTeethFn(item.number, _vm.selectFour),"placement":"bottom","trigger":"click"},on:{"after-leave":function($event){return _vm.popoverHide(index, 'four', item)}}},[_c('div',{staticClass:"flex-y-c"},[_c('el-select',{staticStyle:{"width":"2.2rem"},attrs:{"size":"medium","placeholder":_vm.$t('cases.createDiagnosis.controlgap')},on:{"change":function (val) { return _vm.selectChange(val, item, 'teethFour', _vm.selectFour); }},model:{value:(item.spacingType),callback:function ($$v) {_vm.$set(item, "spacingType", $$v)},expression:"item.spacingType"}},_vm._l((_vm.options),function(it){return _c('el-option',{key:it.value,attrs:{"label":it.label,"value":it.value}})}),1),_c('div',{staticClass:"ml20 wc_btn",class:{ active_btn: item['maintain'] },on:{"click":function($event){return _vm.selectChange('maintain', item, 'teethFour', _vm.selectFour)}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createDiagnosis.wcjx"))+" ")])],1),_c('div',{staticClass:"interval_input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.spacingType === '1' || item.spacingType === '2'),expression:"item.spacingType === '1' || item.spacingType === '2'"}],staticClass:"posr"},[_c('el-input',{staticClass:"input_width",attrs:{"value":item.width,"placeholder":item.spacingType == '1'
                  ? _vm.$t('cases.createDiagnosis.kdjxz')
                  : _vm.$t('cases.createDiagnosis.sxjxz')},on:{"change":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })},"input":function($event){return _vm.disposeNumber({ key: item, value: arguments[0] })}}}),_c('span',{staticClass:"input_mm"},[_vm._v("mm")])],1)]),(index !== _vm.teethFour.length - 1)?_c('div',{staticClass:"com_img",class:( _obj = {}, _obj[{
              irdt: 'purple_kd',
              admin: 'blue_kd',
              clinic: 'blue_kd',
            }[_vm.queryPort] || 'big_kd'] = item['spacingType'] === '1', _obj[{
              irdt: 'purple_sx',
              admin: 'blue_sx',
              clinic: 'blue_sx',
            }[_vm.queryPort] || 'big_sx'] = item['spacingType'] === '2', _obj[{
              irdt: 'purple_wc',
              admin: 'blue_wc',
              clinic: 'blue_wc',
            }[_vm.queryPort] || 'big_wc'] = item['maintain'], _obj[{
              irdt: 'purple_gb',
              admin: 'blue_gb',
              clinic: 'blue_gb',
            }[_vm.queryPort] || 'big_gb'] = item['spacingType'] === '3', _obj['curp hvr-bd'] =  _vm.selectTeethFn(item.number, _vm.selectFour), _obj ),attrs:{"slot":"reference"},on:{"click":function($event){return _vm.resetNumber(item, 'teethFour', index)}},slot:"reference"}):_vm._e()])],1)}),0):_vm._e(),(_vm.find(_vm.totalTeeth, function (item) { return item.spacingType || item.maintain; }))?_c('div',{staticClass:"detail",class:{ detail_old: _vm.isOldCase }},[_vm._l((_vm.totalTeeth),function(item,index){return [(item.spacingType || item.maintain)?_c('p',{key:index,staticClass:"fz16rem mb1rem"},[_vm._v(" "+_vm._s(item.number)+", "+_vm._s(item.nextNumber)+" "+_vm._s(_vm.isShowText[item.spacingType] || (item.maintain ? _vm.$t("cases.createDiagnosis.maintain") : ""))+_vm._s(_vm.$t("cases.createDiagnosis.spacing"))+" "),(item.width)?_c('span',[_vm._v(_vm._s(item.width)+"mm")]):_vm._e()]):_vm._e()]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }